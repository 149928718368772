import React from "react"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/pricing/hero"
import Tools from "components/sections/pricing/tools"
import DataPoints from "components/sections/pricing/dataPoints"
import Compare from "components/sections/pricing/compare"
import AllFeatures from "components/sections/pricing/allFeatures"

import DataFeatures from "components/sections/pricing/dataFeatures"
import Layers from "components/sections/pricing/layers"
import Trial from "components/common/trial"
import { seoTitleSuffix } from "config"

const PricingPage = ({ data }) => {
  const { frontmatter } = data.pricing.edges[0].node

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: frontmatter.useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout headerContent={data.header.edges} footerContent={data.footer.edges}>
        <SEO
          frontmatter={frontmatter}
        />
        <Hero content={data.hero.edges} />
        <Compare content={data.compare.edges} />
        <AllFeatures content={data.allFeatures.edges} />
        <Layers content={data.layers.edges} />
        <div className="gradient">

        </div>
      </Layout>
    </GlobalStateProvider>
  )
}


export default PricingPage

export const pageQuery = graphql`
  {
    pricing: allMdx(filter: { fileAbsolutePath: { regex: "/pricing/fr/pricing/" } }) {
      edges {
        node {
          frontmatter {
            title
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/pricing/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            mdxbgImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
          exports {
            plans {
              name
              title
              subtitle
              icon
              descriptionLine1
              descriptionLine2
              type
              buttonText
              buttonLink
              items {
                title
                tooltip
              }
              color
            }
          }
        }
      }
    }
    compare: allMdx(filter: { fileAbsolutePath: { regex: "/pricing/compare/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          body
        }
      }
    }
    allFeatures: allMdx(filter: { fileAbsolutePath: { regex: "/pricing/allFeatures/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          body
        }
      }
    }
    tools: allMdx(filter: { fileAbsolutePath: { regex: "/pricing/tools/" } }) {
      edges {
        node {
          frontmatter {
            title
            titlePrefix
          }
          exports {
            shownItems
            tools {
              alt
              logo {
                childImageSharp {
                  gatsbyImageData(width: 120, formats: [WEBP, AVIF], quality: 90)
                }
              }
              logoHover {
                childImageSharp {
                  gatsbyImageData(width: 120, formats: [WEBP, AVIF], quality: 90)
                }
              }
            }
          }
        }
      }
    }
    datapoints: allMdx(filter: { fileAbsolutePath: { regex: "/pricing/datapoints/" } }) {
      edges {
        node {
          exports {
            items {
              name
              header
              icon
              plans {
                imp
                subs
              }
            }
          }
        }
      }
    }
    datafeatures: allMdx(filter: { fileAbsolutePath: { regex: "/pricing/datafeatures/" } }) {
      edges {
        node {
          frontmatter {
            title
            buttonText
            buttonLink
          }
          exports {
            features {
              name
              plans
            }
          }
        }
      }
    }
    layers: allMdx(filter: { fileAbsolutePath: { regex: "/pricing/layers/" } }) {
      edges {
        node {
          exports {
            shownItems
            items {
              title
              description
              link
              logo {
                childImageSharp {
                  gatsbyImageData(width: 50, quality: 90)
                }
              }
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
